<template>
  <div
    class="flex items-center justify-center"
    :style="{ height: '100vh' }"
  >
    <div class="flex items-center justify-center w-5/6">
      <div
        class="flex items-center justify-center w-full md:w-1/2"
        :style="{ height: '100vh' }"
      >
        <div class="flex flex-col w-full md:px-16">
          <img
            class="w-3/4 my-4"
            :src="`https://${platformAssetUrl}/images/assets/${assetSubDirectory ? assetSubDirectory + '/' : ''}app-branding/logo-large.png`"
          >
          <h1 class="w-3/4 -ml-1 text-6xl font-bold">
            Backoffice
          </h1>
          <p>Ihr zentrales Tool zur Verwaltung aller Büros, deren Einstellungen und allen Nutzern.</p>
          <button
            class="mt-5 btn-primary"
            @click="$auth.loginWithRedirect()"
          >
            Login
          </button>
        </div>
      </div>
      <div
        class="items-center justify-center hidden w-1/2 md:flex"
        :style="{ height: '100vh' }"
      >
        <div
          id="player-login"
          class="lottie-player"
        />
      </div>
    </div>
  </div>
</template>
<script>
import branding from '@/mixins/branding'
import lottie from 'lottie-web'
const animationData = () => import('../../../public/animations/management.json')

export default {
  mixins: [branding],
  mounted () {
    animationData().then((data) => {
      this.anim = lottie.loadAnimation({
        container: document.getElementById('player-login'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: data
      })
      this.anim.setSpeed(0.5)
    })
  }
}
</script>
<style scoped>
@media (min-width: 900px) {
  .lottie-player{  width: 500px !important; }
}

.lottie-player{
  width: 200px;
}
.btn-primary{
  width: 250px;
}
</style>
