<template>
  <div class="flex flex-col">
    <Feature :feature-slug="featureNames.SSO">
      <label class="inline-flex items-center mb-4">
        <input
          v-model="user.isSso"
          type="checkbox"
          class="w-4 h-4"
        >
        <span class="ml-2 text-base normal-case">Login über SSO Provider</span>
      </label>
    </Feature>

    <div
      v-if="!user.isSso"
      class="w-full px-2 mb-6 -mx-2 form-group"
    >
      <input-text-validated
        ref="user.salutation"
        v-model="user.salutation"
        input-class="w-1/2"
        label="Anrede"
        placeholder="Herr"
        :disabled="isDisabled('salutation')"
      />
    </div>
    <div
      v-if="!user.isSso"
      class="flex flex-wrap mb-6 -mx-2"
    >
      <div class="w-1/2 px-2 form-group">
        <input-text-validated
          ref="user.firstname"
          v-model="user.firstname"
          input-class="w-full"
          label="Vorname"
          placeholder="Max"
          rules="required"
          :disabled="isDisabled('firstname')"
        />
      </div>

      <div class="w-1/2 px-2 form-group">
        <input-text-validated
          ref="user.lastname"
          v-model="user.lastname"
          input-class="w-full"
          label="Nachname"
          placeholder="Makler"
          rules="required"
          :disabled="isDisabled('lastname')"
        />
      </div>
    </div>

    <div class="flex flex-col w-full mb-6">
      <input-text-validated
        ref="user.email"
        v-model="user.email"
        input-class="w-full"
        label="E-Mail"
        placeholder="max.makler@max-makler.immobilien"
        rules="required|email"
        :disabled="isDisabled('email')"
      />
    </div>

    <div v-if="notificationSettings">
      <label class="block w-full">
        BENACHRICHTIGUNGEN:
      </label>
      <div class="flex flex-row justify-between w-full mt-3 mb-3">
        <span class="ml-2 text-base normal-case">Neue Conversion: </span>

        <label class="inline-flex items-center">
          <input
            v-model="notificationSettings.newConversion.sms"
            type="checkbox"
            class="w-4 h-4 ml-4 border-2 border-gray-600 form-checkbox color-grey"
            disabled
          >
          <span class="ml-2 text-base normal-case">SMS</span>
          <input
            v-model="notificationSettings.newConversion.email"
            type="checkbox"
            class="w-4 h-4 ml-4 border-2 border-gray-600 form-checkbox color-grey"
            disabled
          >
          <span class="ml-2 text-base normal-case">E-Mail</span>
        </label>
      </div>
      <div class="flex flex-row justify-between w-full mb-3">
        <span class="ml-2 text-base normal-case">Lead zugewiesen: </span>

        <label class="inline-flex items-center">
          <input
            v-model="notificationSettings.leadAssigned.sms"
            type="checkbox"
            class="w-4 h-4 ml-4 border-2 border-gray-600 form-checkbox color-grey"
            disabled
          >
          <span class="ml-2 text-base normal-case">SMS</span>

          <input
            v-model="notificationSettings.leadAssigned.email"
            type="checkbox"
            class="w-4 h-4 ml-4 border-2 border-gray-600 form-checkbox color-grey"
            disabled
          >
          <span class="ml-2 text-base normal-case">E-Mail</span>
        </label>
      </div>
      <Feature
        v-slot="{feature}"
        :feature-slug="featureNames.NOTIFICATIONS"
      >
        <div
          v-if="feature.config.hasGeneralNotifications"
          class="flex flex-row justify-between w-full mb-3"
        >
          <span class="ml-2 text-base normal-case">BOTTIMMO Benachrichtigungen: </span>

          <label class="inline-flex items-center">
            <input
              v-model="notificationSettings.news.email"
              type="checkbox"
              class="w-4 h-4 ml-4 border-2 border-gray-600 form-checkbox color-grey"
              disabled
            >
            <span class="ml-2 text-base normal-case">E-Mail</span>
          </label>
        </div>
      </Feature>
    </div>
    <div v-if="hasAuthorization">
      <div class="pt-6">
        <label class="block w-full">
          Berechtigungsstufe:
        </label>
        <div class="role-select">
          <select
            v-model="user.role.id"
            class="w-1/2 form-select"
          >
            <option
              v-for="{id: roleId, name} in roles"
              :key="roleId"
              :value="roleId"
            >
              {{ name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GET_ROLES from '@/graphql/GetRoles.gql'

import InputTextValidated from '@/components/form/text-validated'
import feature from '@/mixins/feature'

export default {
  components: { InputTextValidated },
  mixins: [feature],
  props: {
    user: {
      type: Object,
      required: true
    },
    companyId: {
      type: String,
      required: true
    },
    disabled: {
      type: [Boolean, Array],
      default: false
    },
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      hasAuthorization: false,
      roles: []
    }
  },
  computed: {
    notificationSettings () {
      return this.settings?.notificationSettings
    }
  },
  apollo: {
    roles: {
      query: GET_ROLES,
      update ({ roles }) {
        if (!this.user.role.id) {
          this.user.role.id = roles.find(role => role.isDefault)?.id
        }
        return roles
      }
    }
  },
  async created () {
    const companyFeatures = await this.$features.getFeaturesForCompany(this.companyId, {
      apolloProvider: this.$apollo.provider
    })
    this.hasAuthorization = companyFeatures[this.featureNames.AUTHORIZATION].isActive
  },
  methods: {
    isDisabled (field) {
      if (this.disabled) {
        return this.disabled === true ? true : this.disabled.includes(field)
      }

      return false
    }
  }
}
</script>

<style lang="postcss" scoped>
.color-grey {
  color: grey;
}
</style>
