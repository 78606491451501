import { backofficeBranding } from '@/lib/features'

export default {
  computed: {
    platformAssetUrl () {
      return backofficeBranding?.isActive && backofficeBranding?.config?.assetUrl
    },
    platform () {
      return backofficeBranding?.isActive && backofficeBranding?.config?.platform
    },
    readablePlatformName () {
      return backofficeBranding?.isActive && backofficeBranding?.config?.readablePlatformName
    },
    landingpageBaseDomain () {
      return backofficeBranding?.isActive && backofficeBranding?.config?.landingpageBaseDomain
    },
    assetSubDirectory () {
      return backofficeBranding?.isActive && backofficeBranding?.config?.assetSubDirectory
    },
    hasInactiveEmailHandling () {
      return backofficeBranding?.isActive && backofficeBranding?.config?.hasInactiveEmailHandling
    }
  }
}
