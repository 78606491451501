<template>
  <div class="flex flex-col">
    <div
      v-if="company.slug"
      class="flex flex-row w-full p-10 py-2"
    >
      <img
        v-if="company.images && company.images.logo"
        :src="company.images.logo.url"
        class="object-contain h-24 mr-6"
        height="96"
        width="96"
      >
      <div class="flex flex-col flex-grow min-w-0 max">
        <div class="flex items-center gap-2">
          <ColorBadge :text="company.platform">
            {{ company.platform }}
          </ColorBadge>
          <span class="text-4xl font-semibold text-gray-800 truncate">
            {{ company.name }}
          </span>
        </div>
        <div class="flex items-end gap-2">
          <Feature :feature-slug="featureNames.BILLWERK">
            <span class="px-2 py-1">{{ company.debtorId || company.customerNumber }}</span>
          </Feature>
          <span class="px-2 py-1 font-bold">{{ company.slug }}</span>
          <ColorBadge :text="company.productType">
            {{ company.productType }}
          </ColorBadge>
          <a
            v-if="company.zohoUrl"
            class="pl-4 font-bold"
            :href="company.zohoUrl"
            target="_blank"
          >CRM <fa :icon="faLink" /></a>
        </div>
      </div>
    </div>
    <state-update
      :company-id="company.id"
      :company-name="company.name"
      :is-onboarding-data-complete="company.isOnboardingDataComplete"
    />
  </div>
</template>

<script>
import StateUpdate from './stateUpdate.vue'
import { faLink } from '@fortawesome/free-solid-svg-icons'

import feature from '@/mixins/feature'
import ColorBadge from '../common/ColorBadge.vue'

export default {
  components: {
    StateUpdate, ColorBadge
  },
  mixins: [feature],
  props: {
    company: { type: Object, default: () => ({}) }
  },
  created () {
    this.faLink = faLink
  }
}
</script>
