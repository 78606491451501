<template>
  <span
    v-if="text"
    :class="['inline-flex items-center px-2 py-1 rounded font-bold']"
    :style="colorStyle"
  >
    <slot />
  </span>
</template>

<script>
// Static cache object to store computed colors
const colorCache = new Map()

export default {
  name: 'ColorBadge',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    colorStyle () {
      if (!this.text) {
        return {
          backgroundColor: '#f3f4f6',
          color: '#1f2937'
        }
      }

      // Check if colors are already in cache
      if (colorCache.has(this.text)) {
        return colorCache.get(this.text)
      }

      const hue = Array.from(this.text).reduce(
        (hash, char) => ((hash << 5) + hash) + char.charCodeAt(0), 5381
      ) % 360

      const backgroundColor = this.hslToHex(hue, 85, 90)
      const textColor = this.hslToHex(hue, 75, 35)

      const style = {
        backgroundColor,
        color: textColor
      }

      // Store in cache
      colorCache.set(this.text, style)

      return style
    }
  },
  methods: {
    hslToHex (h, s, l) {
      l /= 100
      const a = s * Math.min(l, 1 - l) / 100
      const f = n => {
        const k = (n + h / 30) % 12
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
        return Math.round(255 * color).toString(16).padStart(2, '0')
      }
      return `#${f(0)}${f(8)}${f(4)}`
    }
  }
}
</script>
