<template>
  <div
    v-if="company"
    id="company-details"
  >
    <div class="flex items-center justify-between px-10 py-8 bg-gray-100 border-b rounded-t-lg">
      <h1 class="text-xl font-semibold tracking-wider text-gray-800 uppercase">
        Immobilienfirma
      </h1>
      <button
        v-if="Object.keys(pendingChangesForRegeneration).length > 0"
        class="p-2 px-6 font-semibold text-yellow-600 uppercase bg-yellow-300 rounded hover:bg-yellow-400 hover:text-yellow-800"
        @click="showCompanyMutations()"
      >
        Ausstehende Kundenänderungen anzeigen
      </button>
      <router-link
        :to="`/companies`"
        class="flex items-center font-semibold text-gray-600 uppercase hover:text-gray-800"
      >
        Zurück zur Übersicht
      </router-link>
    </div>
    <company-header :company="company" />

    <tabs>
      <div class="p-10 pt-0">
        <tab name="Konfiguration">
          <base-data :company="company" />
          <Feature
            v-if="hasRole(ROLES.SUPER_ADMIN)"
            :feature-slug="featureNames.HOPPERMATION"
          >
            <email-verification :company="company" />
          </Feature>
          <Feature :feature-slug="featureNames.BACKOFFICE_ADMIN">
            <tags :company="company" />
          </Feature>
          <images
            :images="company.images"
            :company-id="companyId"
          />
          <Feature
            v-if="hasRole(ROLES.SUPER_ADMIN)"
            v-slot="{feature}"
            :feature-slug="featureNames.VALUATION"
          >
            <phone-verification
              v-if="feature.config.isPhoneNumberVerificationEnabled
                && feature.config.canChangePhoneNumberVerification
                && companySettings"
              :company-id="companyId"
              :settings="companySettings"
              :refetch-settings="refetchCompanySettings"
            />
          </Feature>
          <theme
            :company-id="companyId"
            :theme="company.theme"
          />
          <ambassador
            :ambassador="company.ambassador"
            :company-id="companyId"
          />
          <deployment
            v-if="hasRole(ROLES.SUPER_ADMIN)"
            :site="company.site"
            :company-id="companyId"
          />
        </tab>

        <tab name="Landingpages">
          <landingpages :company="company" />
        </tab>

        <tab name="Makler">
          <Feature :feature-slug="featureNames.BACKOFFICE_LEADS">
            <lead-list :company-id="companyId" />
          </Feature>
          <users :company-id="companyId" />
        </tab>
        <tab
          v-if="isFeatureActive(featureNames.CONTRACT) || isFeatureActive(featureNames.BILLWERK)"
          name="Vertrag"
        >
          <Feature
            :feature-slug="featureNames.BILLWERK"
            use-is-enabled
          >
            <billing-provider
              :company="company"
            />
          </Feature>
          <Feature
            v-if="!isExpertCompany()"
            :feature-slug="featureNames.BILLWERK"
            use-is-enabled
          >
            <manual-upgrade
              :company-id="companyId"
              :product-type="company.productType"
            />
          </Feature>
          <Feature
            v-if="!isLightCompany()"
            :feature-slug="featureNames.BILLWERK"
            use-is-enabled
          >
            <manual-downgrade
              :company-id="companyId"
              :product-type="company.productType"
            />
          </Feature>
          <billing
            :company-id="companyId"
          />
          <contracts
            :company-id="companyId"
          />
        </tab>
        <tab
          v-if="hasRole(ROLES.SUPER_ADMIN)"
          name="Ansprechpartner"
        >
          <contact-persons :company-id="companyId" />
        </tab>
        <tab
          v-if="hasRole(ROLES.SUPER_ADMIN)"
          name="Einstellungen"
        >
          <settings :company-id="companyId" />
        </tab>
        <tab
          v-if="isFeatureActive(featureNames.PERFORMANCE_MARKETING)"
          name="Performance"
        >
          <performance :company-id="companyId" />
        </tab>
        <tab
          v-if="isFeatureActive(featureNames.STATISTIC_DASHBOARD)"
          name="Erfolgsanalyse"
        >
          <StatisticDashboard :company-id="companyId" />
        </tab>
      </div>
    </tabs>
  </div>
</template>

<script>

import PhoneVerification from '@/components/company/phoneVerification.vue'
import Tags from '@/components/company/tags'
import BaseData from '@/components/company/base-data'
import Theme from '@/components/company/theme'
import LeadList from '@/components/leads/list'
import Ambassador from '@/components/company/ambassador'
import Users from '@/components/company/users'
import Deployment from '@/components/company/deployment'
import Settings from '@/components/company/settings'
import Landingpages from '@/components/landingpages'
import Images from '@/components/company/images'
import Tabs from '@/components/navigation/tabs'
import Tab from '@/components/navigation/tab'
import Contracts from '@/components/company/contract/contracts'
import Billing from '@/components/company/billing'
import BillingProvider from '@/components/company/billing-provider'
import ManualUpgrade from '@/components/company/manual-upgrade'
import ManualDowngrade from '@/components/company/manual-downgrade'
import ContactPersons from '@/components/company/contact-persons.vue'
import CompanyHeader from '@/components/company/header'
import Performance from '@/components/company/performance.vue'
import StatisticDashboard from '@/components/company/statistic-dashboard.vue'
import CompanyMutations from '@/components/modals/company/CompanyMutations.vue'
import GET_PENDING_CHANGES_FOR_REGENERATION from '@/graphql/GetPendingChangesForRegeneration.gql'
import GET_COMPANY from '@/graphql/GetCompany.gql'
import GET_COMPANY_SETTINGS from '@/graphql/GetCompanySettings.gql'
import featureMixin from '@/mixins/feature'
import role from '@/mixins/role'
import EmailVerification from '@/components/company/emailVerification.vue'

export default {
  components: {
    Tags,
    BaseData,
    Theme,
    LeadList,
    Landingpages,
    Ambassador,
    Users,
    Deployment,
    Settings,
    Images,
    PhoneVerification,
    Tabs,
    Tab,
    Contracts,
    Billing,
    BillingProvider,
    ManualUpgrade,
    ManualDowngrade,
    ContactPersons,
    CompanyHeader,
    Performance,
    StatisticDashboard,
    EmailVerification
  },
  mixins: [featureMixin, role],
  data () {
    return {
      phoneVerificationEnabled: false,
      pendingChangesForRegeneration: {}
    }
  },
  computed: {
    companyId () {
      return this.$route.params.id
    }
  },
  methods: {
    showCompanyMutations () {
      this.$modal.show(
        CompanyMutations,
        { companyId: this.company.id },
        { width: 800 }
      )
    },
    async refetchCompanySettings () {
      await this.$apollo.queries.companySettings.refetch()
    },
    isLightCompany () {
      return this.company.productType === 'LIGHT'
    },
    isExpertCompany () {
      return this.company.productType === 'EXPERT'
    }
  },
  apollo: {
    company: {
      query: GET_COMPANY,
      variables () {
        return {
          id: this.companyId
        }
      }
    },
    companySettings: {
      query: GET_COMPANY_SETTINGS,
      variables () {
        return {
          id: this.companyId
        }
      }
    },
    pendingChangesForRegeneration: {
      query: GET_PENDING_CHANGES_FOR_REGENERATION,
      variables () {
        return { companyId: this.companyId }
      }
    }
  }
}
</script>
